import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { useResizeObserver } from "@tm/utils"
import { useEffect, useMemo, useState } from "react"
import { MemoTooltip } from "./MemoTooltip"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ErpIcons } from "../ErpIcons"
import { AdditionalInformation } from "./AdditionalInformation"
import { ButtonAction } from "./ButtonAction"
import { FoundBy } from "./FoundBy"
import { IconAction } from "./IconAction"

import { LessCenterActions } from "./LessCenterActions"

export function CenterActions() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const articleListExpanded = useDefaultArticleItemState((x) => x.expanded)
    const articleFoundBy = useDefaultArticleItemState((x) => x.foundBy)
    const article = useDefaultArticleItemState((x) => x.article)
    const memoTooltipIcon = useDefaultArticleItemState((x) => x.memoTooltipIcon)
    const defaultArticleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos?.default)
    const erpInfos = defaultArticleErpInfos?.state === "success" ? defaultArticleErpInfos.response : undefined
    const hasAdditionalInfo = article.additionalReferenceArticleInformation && article.additionalReferenceArticleInformation.links.length !== 0

    const { breakpoints } = useTheme()
    const isHighResolution = useMediaQuery(breakpoints.up("xl"))
    const [isFilterExpanded, setIsFilterExpanded] = useState(false)

    const isNarrowContainer = !articleListExpanded && isFilterExpanded

    const iconActionList = useMemo(() => articleActions.filter((x) => x.area === "CENTER_ICONS"), [articleActions])
    const buttonActionList = useMemo(() => articleActions.filter((x) => x.area === "CENTER_BUTTONS"), [articleActions])
    const hasFewIcons = iconActionList.length + buttonActionList.length + +!!articleFoundBy + +hasAdditionalInfo <= 2

    function handleResizeContainer(entry?: ResizeObserverEntry) {
        const containerWidth = entry?.target.parentElement?.clientWidth
        containerWidth && setIsFilterExpanded(containerWidth < 597)
    }
    const [stackRef, unsubResizeObserver] = useResizeObserver(handleResizeContainer)

    useEffect(() => unsubResizeObserver, [unsubResizeObserver])

    return (
        <Stack ref={stackRef} direction="row" justifyContent="flex-end" ml={2} flex={1} flexWrap="wrap">
            <Stack direction="row" spacing={1} gap="10px" alignItems="center">
                {erpInfos?.memos && memoTooltipIcon && <MemoTooltip memos={erpInfos.memos} iconName={memoTooltipIcon} />}
                <ErpIcons position="articleInfo" />
                {!isNarrowContainer || hasFewIcons ? (
                    <>
                        {articleFoundBy && <FoundBy articleFoundBy={articleFoundBy} />}
                        {iconActionList.map((action) => (
                            <IconAction key={action.id} action={action} />
                        ))}
                        {hasAdditionalInfo && <AdditionalInformation article={article} />}
                        {buttonActionList.map((action) => {
                            return <ButtonAction key={action.id} action={action} hideText={!isHighResolution || !articleListExpanded} />
                        })}
                    </>
                ) : (
                    <LessCenterActions iconActionList={iconActionList} buttonActionList={buttonActionList} />
                )}
            </Stack>
        </Stack>
    )
}
