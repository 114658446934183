import { Button, Tooltip } from "@mui/material"
import { Box, styled, Theme } from "@mui/system"
import { ArticleAttribute } from "@tm/models"
import { bem, classes } from "@tm/utils"

type SelectableArticleAttribute = ArticleAttribute & { selected?: boolean }

type Props = {
    items: SelectableArticleAttribute[]
    onSelect?: (article: ArticleAttribute) => void
}

export default function TyresIcons({ items, onSelect }: Props) {
    const renderIcon = (attr: SelectableArticleAttribute, key: number) => (
        <Tooltip key={key} title={attr.description}>
            <StyledButton
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className={classes(bem("buttonNumber", attr.value), attr.selected && "selected")}
                value={attr.value}
                onClick={() => onSelect?.(attr)}
            >
                {attr.value}
            </StyledButton>
        </Tooltip>
    )

    return <IconContainer>{items.map(renderIcon)}</IconContainer>
}

const IconContainer = styled(Box)(({ theme }) => ({
    minWidth: "6em",
    ".selected": {
        outline: "1px",
        outlineColor: theme.palette.primary.main,
        outlineStyle: "auto",
    },
}))

const getColorByValue = (value: string, theme: Theme) => {
    switch (value) {
        case "A":
            return "#2d7327"
        case "B":
            return "#55d545"
        case "C":
            return "#b5e941"
        case "D":
            return "#f6ed4e"
        case "E":
            return "#f6a328"
        case "F":
            return "#dd901f"
        case "G":
            return "#e12816"
        default: // Default color
            return theme.palette.primary.main
    }
}

const StyledButton = styled(Button)<{ value: string }>(({ value, theme }) => ({
    cursor: "default",
    minWidth: "24px",
    height: "24px",
    marginLeft: "0.2em",
    color: theme.palette.common.white,
    textShadow: "0 0 3px #202020",
    backgroundColor: getColorByValue(value, theme),
    "&:hover": {
        textShadow: "none",
        backgroundColor: getColorByValue(value, theme),
    },
    "&:active, &:focus": {
        // backgroundColor: getColorByValue(value, theme), // future task
    },
}))
