const heightObservables: { [key: string]: HTMLElement[] } = {}
const heightObservers: { [key: string]: [HTMLElement, ResizeObserver][] } = {}

export function useHeighAdjustment() {
    function setHeightAdjustment(element: HTMLElement, elementNames: string[]) {
        // if there are more than one elementNames we should check if they are available. in case they are not, no resizing should happen.
        elementNames.forEach((name) => {
            let observerPairs = heightObservers[name]

            if (!observerPairs) {
                heightObservers[name] = []
                observerPairs = heightObservers[name]
            }

            if (observerPairs.some((x) => x[0] === element)) {
                return removeHeightAdjustment
            }

            const observer = new ResizeObserver(() => {
                let elements: HTMLElement[] = []

                Object.keys(heightObservables).forEach((key) => {
                    elements = [...elements, ...(heightObservables[key] || [])]
                })

                const height = elements.reduce((prev, curr) => prev + curr.clientHeight, 0)

                element.style.height = `${height}px`
            })

            heightObservers[name] = [...observerPairs, [element, observer]]
            observerPairs = heightObservers[name]

            const observables = heightObservables[name]

            if (!observables) {
                return removeHeightAdjustment
            }

            observables.forEach((el) => observer.observe(el))
        })

        function removeHeightAdjustment() {
            Object.keys(heightObservers).forEach((key) => {
                const observerPair = heightObservers[key].find((x) => x[0] === element)
                if (!observerPair) {
                    return
                }
                observerPair[1].disconnect()
                heightObservers[key] = heightObservers[key].filter((x) => x[0] !== element)
            })
        }

        return removeHeightAdjustment
    }

    function allowHeightObservation(element: HTMLElement, name: string) {
        let observables = heightObservables[name]
        if (!observables) {
            heightObservables[name] = []
            observables = heightObservables[name]
        }

        if (observables.indexOf(element) !== -1) {
            return declineHeightObservation
        }

        observables = [...observables, element]
        heightObservables[name] = [...heightObservables[name], element]
        ;(heightObservers[name] || []).forEach((observer) => observer[1].observe(element))

        function declineHeightObservation() {
            heightObservables[name] = observables.filter((x) => x != element)
            observables = heightObservables[name]
        }

        return declineHeightObservation
    }

    return {
        setHeightAdjustment,
        allowHeightObservation,
    }
}
