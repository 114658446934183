import { VehicleType } from "./enumerations"
import { ESubModules } from "./user-context"

export type ActivateModuleProps = {
    routes?: Record<string, string> | undefined
    demoActivationInstanstly?: boolean
    workTaskId?: string
}

export type ActivateModuleRef = null | {
    handleActivateModule: (
        moduleGroupId: ModuleGroupId,
        module: ActivatableModule,
        moduleOption?: ModuleOption,
        modulePackage?: ModulePackage
    ) => void
}

export type GetActivatableModulesResponse = {
    moduleGroups: Array<ModuleGroup>
}

export type ModuleGroup = {
    id: ModuleGroupId
    display: boolean
    modules: Array<ActivatableModule>
}

export enum EModuleGroupId {
    none = "none",
    gpi = "gpi",
    technicalData = "technicalData",
    technicalDataTruck = "technicalDataTruck",
    fastCalculator = "fastCalculator",
    damageCalculation = "damageCalculation",
    inspectionData = "inspectionData",
    repairTimes = "repairTimes",
    tyres = "tyres",
}

export enum EModuleId {
    // demo
    dat = "dat",
    eurotax = "eurotax",
    tecrmi = "tecrmi",
    autodata = "autodata",
    haynespro = "haynespro",
    topmotive = "topmotive",
    datglass = "datglass",
    // normal
    alldata = "alldata",
    temot = "temot",
    tmtires = "tmtires",
    vehicle = "vehicle",
}

export type ModuleGroupId = `${EModuleGroupId}`
export type ModuleId = `${EModuleId}`
export type ModuleMode = "NONE" | "SD" | "TD"

export type ActivatableModule = {
    id: ModuleId
    display: boolean
    mdmModuleId: ESubModules
    externalId: string
    name: string
    shortDescriptionUrl: string
    longDescriptionUrl: string
    vehicleType: VehicleType
    moduleOptions: ModuleOptions | undefined
    modulePackages?: ModulePackages
    hasServiceData?: boolean
    explanationVideoUrl?: string
}

export type ModuleOptions = {
    demo?: ModuleOption
    full?: ModuleOption
}

export type ModuleOption = {
    type: ModuleOptionType
    modulePrice?: ModuleOptionPrice
    active?: boolean
    activatable?: boolean
    amountOfDays?: number
    from?: Date
    to?: Date
}

export type ModulePackage = {
    id: string
    name: string
    moduleOptions: ModuleOptions
}

export type ModulePackages = {
    currentPackage: ModulePackage
    higherPackage: ModulePackage
}

export type ModuleInformation = {
    id: string
    externalId?: string
    name: string
    description?: string
    externalUrl?: string
    shortDescriptionImage?: string
    longDescriptionImage?: string
    explanationVideoUrl?: string
}

export enum ModuleOptionType {
    Unknown = 0,
    Demo = 1,
    Full = 2,
}

export type ModuleOptionPrice = {
    type: ModuleOptionPriceType
    value: number
    currencySymbol: string
}

export enum ModuleOptionPriceType {
    Unknown = 0,
    PricePerMonth = 1,
    PricePerUse = 2,
}

export type SetModuleActivationRequest = {
    moduleGroupId: string
    moduleId: string
    mdmModuleId: ESubModules
    externalModuleId?: string
    moduleOption: ModuleOption
    modulePackage?: ModulePackage
}

export type SetModuleActivationResponse = {
}
