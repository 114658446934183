import * as React from "react"
import VendorScrollbar from "react-custom-scrollbars-2"
import { bindMethodsToContext } from "../../helper";

export type ScrollbarProps = {
    onScroll?: React.UIEventHandler<any>
    onRef?(e: VendorScrollbar | null): void
    className?: string
}

export default class Scrollbar extends React.Component<ScrollbarProps, any> {
    private __refElement: VendorScrollbar | null
    /**
     *
     */
    constructor(props: ScrollbarProps) {
        super(props);

        bindMethodsToContext(this);
    }

    handleScroll(e: any) {
        const { onScroll } = this.props

        if (onScroll) {
            onScroll(e)
        }
    }

    handleRef(el: VendorScrollbar | null) {
        this.__refElement = el
        const { onRef } = this.props
        if (onRef) {
            onRef(el)
        }
    }

    render() {

        let className = "scrollbar__view"

        if (this.props.className) {
            className += " " + this.props.className
        }

        return (
            <VendorScrollbar
                onScroll={this.handleScroll.bind(this)}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                ref={this.handleRef.bind(this)}
                renderView={(props: ScrollbarProps) => <div {...props} className={className} />}
                renderTrackHorizontal={(props: ScrollbarProps) => <div {...props} className="scrollbar__track-horizontal" />}
                renderTrackVertical={(props: ScrollbarProps) => <div {...props} className="scrollbar__track-vertical" />}
            >
                {this.props.children}
            </VendorScrollbar>
        )
    }
}
