import { ReactNode, forwardRef } from "react"
import { Alert as MuiAlert, AlertColor, AlertProps, AlertTitle, Box, IconButton, styled, useTheme, rgbToHex } from "@mui/material"
import { Theme } from "@mui/system"
import { Icon } from "../../generics/Icons"

type Props = AlertProps & {
    title?: string
    borderLeft?: boolean | string
    onClose?: () => void
    iconName?: string
    iconColor?: "primary" | "secondary" | "success" | "error" | "info" | "warning" | "highlight"
    size?: "extraSmall" | "small" | "medium"
    fullwidth?: boolean
}

const StyledAlert = styled(MuiAlert, {
    shouldForwardProp: (prop) => !["title", "size", "borderLeft"].includes(prop as string),
})<Omit<Props, "title">>(({ theme, borderLeft, size, severity, iconColor, variant }) => {
    const themeColor = severity && theme.palette?.[severity]
    const iconThemeColor = iconColor && theme.palette?.[iconColor]

    const alertOverwrites = theme.overwrites?.components?.alert ?? {}
    const color = alertOverwrites.font?.color ?? (variant === "filled" ? themeColor?.contrastText : theme.palette.text.primary)

    return {
        opacity: 1,
        borderRadius: "4px",
        backgroundColor: variant === "outlined" ? "#fafafa" : undefined,
        boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.13)",
        ".MuiAlert-icon": {
            paddingTop: "8px",
            color: iconColor ? iconThemeColor?.main || iconColor : "#000",
        },

        ".MuiAlert-message": {
            color,
            "& > *": {
                color,
            },
        },

        ...(size === "small" && {
            padding: "1px 16px",
            ".MuiAlert-message": {
                padding: "5px",
                "& > *": {
                    color,
                    fontSize: "14px",
                    margin: "2px 0",
                    lineHeight: "1",
                },
            },
            ".MuiAlert-icon": {
                color: iconColor ? iconThemeColor?.main || iconColor : "#000",
                padding: "2px 0",
                alignItems: "center",
            },
        }),

        ...(size === "extraSmall" && {
            padding: "1px 6px",
            ".MuiAlert-message": {
                padding: "3px",
                "& > *": {
                    color,
                    fontSize: "12px",
                    margin: "2px 0",
                    lineHeight: "1",
                },
            },
            ".MuiAlert-icon": {
                color: iconColor ? iconThemeColor?.main || iconColor : "#000",
                padding: "2px 0",
                marginRight: "4px",
                alignItems: "center",
                svg: {
                    height: "16px",
                    width: "16px",
                    minHeight: "16px",
                    minWidth: "16px",
                },
            },
        }),

        ...(borderLeft && {
            borderWidth: "0",
            borderLeftWidth: ".5em",
        }),
    }
})

const getMapping = (variant: "standard" | "filled" | "outlined" | undefined, theme: Theme): Partial<Record<AlertColor, ReactNode>> => {
    if (variant === "filled") {
        return {
            error: <Icon name="alert_W_warning" size="24px" />,
            info: <Icon name="alert_W_infomation" size="24px" />,
            success: <Icon name="alert_W_success" size="24px" />,
            warning: <Icon name="alert_W_attention" size="24px" />,
        }
    }

    const errorBlack = rgbToHex(theme.palette.error.contrastText).startsWith("#000")
    const infoBlack = rgbToHex(theme.palette.info.contrastText).startsWith("#000")
    const successBlack = rgbToHex(theme.palette.success.contrastText).startsWith("#000")
    const warningBlack = rgbToHex(theme.palette.warning.contrastText).startsWith("#000")

    return {
        error: <Icon name={errorBlack ? "alert_B_warning" : "alert_W_warning"} color="error" size="24px" />,
        info: <Icon name={infoBlack ? "alert_B_infomation" : "alert_W_infomation"} color="info" size="24px" />,
        success: <Icon name={successBlack ? "alert_B_success" : "alert_W_success"} color="success" size="24px" />,
        warning: <Icon name={warningBlack ? "alert_B_attention" : "alert_W_attention"} color="warning" size="24px" />,
    }
}

export const Alert = forwardRef<HTMLDivElement, Props>(
    ({ fullwidth = true, children, title, iconName, onClose, borderLeft, severity, variant, size, ...rest }, ref) => {
        const componentVariant = variant || "filled"
        const theme = useTheme()

        const component = (
            <StyledAlert
                iconMapping={getMapping(componentVariant, theme)}
                size={size}
                ref={ref}
                variant={componentVariant}
                borderLeft={borderLeft}
                action={
                    onClose ? (
                        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                            <Icon name="close" />
                        </IconButton>
                    ) : undefined
                }
                icon={iconName ? <Icon name={iconName} /> : undefined}
                severity={severity}
                {...rest}
            >
                {title && <AlertTitle>{title}</AlertTitle>}
                {children}
            </StyledAlert>
        )

        if (fullwidth) {
            return component
        }

        return <Box sx={{ display: "inline-block" }}>{component}</Box>
    }
)
