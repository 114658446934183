import { ActivatableModule, GetActivatableModulesResponse, ModuleGroupId, ModuleId, ModuleOption, ModulePackage, VehicleType } from "@tm/models"

export function getModuleGroupIconName(moduleGroupId: ModuleGroupId) {
    switch (moduleGroupId) {
        case "fastCalculator":
            return "fast-calculator"
        case "gpi":
            return "group"
        case "technicalData":
        case "technicalDataTruck":
            return "tech-data"
        case "damageCalculation":
            return "damage-calculation"
        default:
            return ""
    }
}

export function getModuleGroupTextId(moduleGroupId: ModuleGroupId, translateText: (key: string | number) => string) {
    switch (moduleGroupId) {
        case "fastCalculator": {
            return translateText(598)
        }
        case "gpi": {
            return translateText(1145)
        }
        case "technicalData": {
            return translateText(12987)
        }
        case "technicalDataTruck": {
            return translateText(12986)
        }
        case "damageCalculation": {
            return translateText(1718)
        }
        default:
            return ""
    }
}

export function getModuleCompareRoute(moduleGroupId?: ModuleGroupId, vehicleType?: VehicleType) {
    switch (moduleGroupId) {
        case "gpi":
            return "gpi"
        case "inspectionData":
        case "repairTimes":
        case "technicalData":
            return vehicleType === VehicleType.CommercialVehicle ? "td-truck" : "td"
        case "technicalDataTruck":
            return "td-truck"
        case "fastCalculator":
            return "fast-calc-next"
        default:
            return ""
    }
}

export function getDemoModule(activatableModules: GetActivatableModulesResponse | undefined, moduleGroupId: ModuleGroupId, moduleId: ModuleId) {
    return activatableModules?.moduleGroups?.find((moduleGroup) => moduleGroup.id === moduleGroupId)?.modules.find((module) => module.id === moduleId)
}

export function getDemoModuleByVehicleType(
    activatableModules: GetActivatableModulesResponse | undefined,
    moduleGroupId: ModuleGroupId,
    moduleId: ModuleId,
    vehicleType: VehicleType
) {
    return activatableModules?.moduleGroups
        ?.find((moduleGroup) => moduleGroup.id === moduleGroupId)
        ?.modules.find((module) => module.id === moduleId && module.vehicleType == vehicleType)
}

export function getCombinedModuleOptionsAndPackages(module: ActivatableModule) {
    const defaultFullOptionModule = module.moduleOptions?.full
    const defaultDemoOptionModule = module.moduleOptions?.demo

    let fullModulePackage: ModulePackage | undefined
    let demoModulePackage: ModulePackage | undefined

    let fullOptionModule: ModuleOption | undefined
    let demoOptionModule: ModuleOption | undefined

    // demo 2.0 - packages
    if (defaultFullOptionModule?.active && module.modulePackages) {
        const fullModulePackageTemp = module.modulePackages.higherPackage?.moduleOptions?.full

        fullModulePackage =
            fullModulePackageTemp?.active || fullModulePackageTemp?.activatable
                ? module.modulePackages.higherPackage
                : module.modulePackages.currentPackage

        if (
            module.modulePackages.higherPackage?.moduleOptions.demo?.active ||
            module.modulePackages.higherPackage?.moduleOptions?.demo?.activatable
        ) {
            demoModulePackage = module.modulePackages.higherPackage
        }

        fullOptionModule = fullModulePackage?.moduleOptions.full
        demoOptionModule = demoModulePackage?.moduleOptions.demo
    }

    demoOptionModule = demoOptionModule ?? defaultDemoOptionModule
    fullOptionModule = fullOptionModule ?? defaultFullOptionModule

    const isFullPackageActive = defaultFullOptionModule?.active && (!fullModulePackage || (fullModulePackage && fullOptionModule?.active))

    return {
        defaultFullOptionModule,
        defaultDemoOptionModule,
        fullModulePackage,
        demoModulePackage,
        fullOptionModule,
        demoOptionModule,
        isFullPackageActive,
    }
}
