import { useLocalization } from "@tm/localization"
import { Box, Tooltip } from "@mui/material"
import { EFilterNames, TyreArticleAttribute } from "@tm/models"
import { getIconByGroup, getTitleByGroup } from "@tm/utils"
import { useMemo } from "react"
import { Icon } from "../../../../../generics/Icons"
import { Descriptions } from "../../../Descriptions"
import TyresIcons from "./TyresIcons"
import { useTyresItemState } from "../../../ContextProviderStates/TyresItemState"

type Props = {
    isCompact?: boolean
}

export const SeasonIconWithTyreEfficiency = ({ isCompact }: Props) => {
    const article = useTyresItemState((x) => x.article)
    const { translateText } = useLocalization()

    const allAttr = useMemo(() => {
        return [...article.attributes?.[0]?.topAttributes, ...article.attributes?.[0]?.articleAttributes]
    }, [article.attributes])

    const renderIcons = () => {
        const fuelEfficiency = allAttr.find((x) => x.group === EFilterNames.fuelEfficiency)
        const wetGripClass = allAttr.find((x) => x.group === EFilterNames.wetGripClass)
        const externalRolling = allAttr.find((x) => x.group === EFilterNames.externalRolling)

        const items: (TyreArticleAttribute & { selected?: boolean })[] = []
        if (fuelEfficiency) {
            items.push(fuelEfficiency)
        }
        if (wetGripClass) {
            items.push(wetGripClass)
        }
        if (externalRolling) {
            items.push(externalRolling)
        }

        return <TyresIcons items={items} />
    }

    return (
        <>
            {!isCompact && <Descriptions />}
            <Box display="flex" flexDirection="row" gap=".2em">
                <Tooltip title={translateText(getTitleByGroup(article.productGroup.season))}>
                    <Icon name={getIconByGroup(article.productGroup.season)} size="1.5em" sx={{ marginRight: ".2em" }} />
                </Tooltip>
                {renderIcons()}
                {isCompact && <Descriptions />}
            </Box>
        </>
    )
}
