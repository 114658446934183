import { memo } from "react"
import { useLocalization } from "@tm/localization"
import { Collapse, Stack } from "@mui/material"
import { NoteItem } from "./NoteItem"
import { SubArticleContainer } from "../SubArticleContainer"
import { useNotes } from "./useNotes"

export const Notes = memo(() => {
    const { translateText } = useLocalization()
    const { handleDelete, handleSave, isLoading, visible, hide, displayNotes } = useNotes()

    return (
        <Collapse in={visible}>
            {visible && (
                <SubArticleContainer title={translateText(14)} onClose={hide} isLoading={isLoading}>
                    <Stack rowGap={1} columnGap={2} display="grid" gridTemplateColumns="auto 1fr auto">
                        {displayNotes.map((note) => (
                            <NoteItem key={note.noteId} note={note} onSave={handleSave} onDelete={handleDelete} isLoading={isLoading} />
                        ))}
                    </Stack>
                </SubArticleContainer>
            )}
        </Collapse>
    )
})
