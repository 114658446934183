import { MileType } from "@tm/models"

export function formatMileage(mileage: string, mileType?: MileType) {
    switch (mileType) {
        case MileType.Mile:
            return `${mileage} mi.`
        case MileType.Kilometer:
        default:
            return `${mileage} km.`
    }
}

export function formatVin(vin: string) {
    return vin.toUpperCase().replace(/I/g, "1").replace(/[OQ]/g, "0")
}

export function noEmojis(value: string): string {
    const emojiPattern =
        /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2B50}\u{2B55}]/gu

    return value.replace(emojiPattern, "")
}
