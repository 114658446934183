import { ElementType, forwardRef } from "react"
import { Button as MuiButton, ButtonProps as MuiButtonProps, buttonClasses, lighten, darken, getLuminance, styled } from "@mui/material"
import { NextComponentButtonColorOverwrites } from "../../theme"

export interface ButtonProps extends MuiButtonProps {
    target?: string
    rel?: string
    component?: ElementType
}

/**
 * Typeguard function to check if the passed `color` is valid to be used as an overwrite color for the `Button` component.
 *
 * @param color A string which should be checked
 * @returns `true` if the passed string is valid otherwise `false`
 */
function isValidColorOverride(color: ButtonProps["color"]): color is keyof NextComponentButtonColorOverwrites {
    return !!color && color !== "inherit"
}

/**
 * Returns a color that is slightly offsetted (darker or lighter depending on the color luminance).
 *
 * @param color The color which should be offsetted
 * @param offset The amount of difference (0 - 1)
 * @returns The offsetted color
 */
function getOffsetColor(color: string, offset = 0.1) {
    return getLuminance(color) > 0.15 ? darken(color, offset) : lighten(color, offset)
}

const ColorButton = styled(MuiButton)<ButtonProps>(({ color, theme, children, startIcon, endIcon }) => {
    // helper
    const { getContrastText } = theme.palette

    const hasIcon = !!startIcon || !!endIcon

    // selected color: primary, secondary, highlight, ...
    const selectedColor = isValidColorOverride(color) ? theme.palette?.[color] : undefined

    // overwrites
    const buttonOverwrites = theme.overwrites?.components?.button ?? {}
    const overwritesBorderRadius = buttonOverwrites.borderRadius
    const overwritesButtonPrimary = buttonOverwrites.primary
    const overwritesButtonOutlined = buttonOverwrites.outlined
    const overwritesButtonSelectedColor = isValidColorOverride(color) ? buttonOverwrites[color] : undefined

    const borderColor = overwritesButtonPrimary?.borderColor ?? "transparent" // TODO: I think we can not always use the `borderColor` of the primary overwrite here
    const borderRadius = overwritesBorderRadius ?? theme.radius?.default ?? "3px"
    const backgroundColor = overwritesButtonPrimary?.backgroundColor ?? theme.palette.grey[300]
    const backgroundColorHover = overwritesButtonPrimary?.hover?.backgroundColor ?? theme.palette.primary.light
    const textTransform = overwritesButtonPrimary?.textTransform ?? "none"
    const fontColor = overwritesButtonPrimary?.fontColor ? overwritesButtonPrimary?.fontColor : getContrastText(backgroundColor)
    const fontFamily = overwritesButtonPrimary?.fontFamily ? overwritesButtonPrimary?.fontFamily : theme.typography.fontFamily
    const fontFamilyOutlined = overwritesButtonOutlined?.fontFamily ? overwritesButtonOutlined?.fontFamily : theme.typography.fontFamily

    return {
        textTransform,
        borderRadius,
        flexShrink: 0,
        minWidth: "15px",
        marginLeft: 0,
        marginRight: 0,
        whiteSpace: "nowrap",
        verticalAlign: "top",
        ...(overwritesButtonPrimary?.letterSpacing && {
            letterSpacing: overwritesButtonPrimary?.letterSpacing,
        }),
        // bordered
        [`&.MuiButton-bordered`]: {
            fontFamily,
            border: `1px solid ${borderColor}`,
            borderRadius,
            backgroundColor,
            color: fontColor,
            ...(overwritesButtonPrimary?.fontWeight && {
                fontWeight: overwritesButtonPrimary?.fontWeight,
            }),
            ...(overwritesButtonPrimary?.fontSize && {
                fontSize: overwritesButtonPrimary?.fontSize,
            }),
            ...(overwritesButtonPrimary?.fontFamily && {
                fontFamily: overwritesButtonPrimary?.fontFamily,
            }),
            ".icon": {
                fill: fontColor,
            },
            ...(selectedColor && {
                backgroundColor: selectedColor.main,
                color: overwritesButtonPrimary?.hover?.text ?? selectedColor.contrastText,
                [`&::before`]: {
                    content: '""',
                    backgroundColor: getLuminance(selectedColor.main) > 0.15 ? selectedColor.dark : selectedColor.light,
                    borderBottomLeftRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    position: "absolute",
                    width: "calc(100% + 2px)",
                    height: "3px",
                    left: "-1px",
                    bottom: "-1px",
                },
                ".icon": {
                    fill: overwritesButtonPrimary?.hover?.text ?? selectedColor.contrastText,
                },
            }),
            "&:hover": {
                backgroundColor: backgroundColorHover,
                color: overwritesButtonPrimary?.hover?.text ?? getContrastText(backgroundColorHover),
                ...(selectedColor && {
                    backgroundColor: overwritesButtonSelectedColor?.hover?.backgroundColor ?? getOffsetColor(selectedColor.main),
                    color: getContrastText(overwritesButtonSelectedColor?.hover?.backgroundColor ?? selectedColor.main),
                    [`&::before`]: {
                        backgroundColor: getOffsetColor(overwritesButtonSelectedColor?.hover?.backgroundColor ?? selectedColor.main, 0.4),
                    },
                }),
                ".icon": {
                    fill: overwritesButtonPrimary?.hover?.text ?? getContrastText(backgroundColorHover),
                },
            },
        },
        // contained button
        [`&.${buttonClasses.contained}`]: {
            fontFamily,
            border: `1px solid ${borderColor}`,
            borderRadius,
            backgroundColor,
            color: fontColor,
            ...(overwritesButtonPrimary?.fontWeight && {
                fontWeight: overwritesButtonPrimary?.fontWeight,
            }),
            ...(overwritesButtonPrimary?.fontSize && {
                fontSize: overwritesButtonPrimary?.fontSize,
            }),
            ...(overwritesButtonPrimary?.fontFamily && {
                fontFamily: overwritesButtonPrimary?.fontFamily,
            }),
            boxShadow: "none",
            ...(selectedColor && {
                backgroundColor: selectedColor.main,
                color: selectedColor.contrastText,
            }),
            ".icon": {
                fill: fontColor,
                ...(selectedColor && {
                    fill: selectedColor.contrastText,
                }),
            },
            "&:hover": {
                backgroundColor: backgroundColorHover,
                color: overwritesButtonPrimary?.hover?.text ?? getContrastText(backgroundColorHover),
                ...(selectedColor && {
                    backgroundColor: overwritesButtonSelectedColor?.hover?.backgroundColor ?? getOffsetColor(selectedColor.main),
                    color:
                        overwritesButtonSelectedColor?.hover?.text ??
                        getContrastText(overwritesButtonSelectedColor?.hover?.backgroundColor ?? selectedColor.main),
                    ".icon": {
                        fill:
                            overwritesButtonSelectedColor?.hover?.text ??
                            getContrastText(overwritesButtonSelectedColor?.hover?.backgroundColor ?? selectedColor.main),
                    },
                }),
                ".icon": {
                    fill: overwritesButtonPrimary?.hover?.text ?? getContrastText(backgroundColorHover),
                },
            },
        },
        // outlined button
        [`&.${buttonClasses.outlined}`]: {
            fontFamily: fontFamilyOutlined,
            color: theme.palette.common.black,
            backgroundColor: "transparent",
            borderColor: buttonOverwrites?.outlined?.borderColor ? buttonOverwrites?.outlined?.borderColor : backgroundColor,
            ...(selectedColor && {
                borderColor: selectedColor.main,
            }),
            svg: {
                transition: "fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
            },
            "&:hover": {
                color: buttonOverwrites?.outlined?.hover?.color ? buttonOverwrites?.outlined?.hover?.color : theme.palette.common.black,
                borderColor: buttonOverwrites?.outlined?.hover?.borderColor ? buttonOverwrites?.outlined?.hover?.borderColor : backgroundColorHover,
                opacity: 1,
                ...(selectedColor && {
                    borderColor: selectedColor.light,
                }),
                svg: {
                    fill: backgroundColorHover,
                    ...(selectedColor && {
                        fill: selectedColor.light,
                    }),
                },
            },
        },
        // text and textLight button
        [`&.${buttonClasses.text}, &.MuiButton-textLight`]: {
            fontFamily,
            border: "1px solid transparent",
            opacity: theme.opacity?.primary,
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "transparent",
            },
            "&.Mui-disabled": {
                [`.${buttonClasses.startIcon}, .${buttonClasses.endIcon} `]: {
                    "& > svg": {
                        opacity: 0.38,
                    },
                },
            },
        },
        // text button
        [`&.${buttonClasses.text}`]: {
            color: selectedColor?.main ?? theme.typography.body1.color,
            "&:hover": {
                color: selectedColor?.dark ?? theme.palette.common.black,
            },
        },
        // textLight button
        [`&.MuiButton-textLight`]: {
            color: selectedColor?.main ?? getOffsetColor(theme.palette.common.white, 0.05),
            "&:hover": {
                color: selectedColor?.light ?? theme.palette.common.white,
            },
        },
        // size - small
        [`&.${buttonClasses.sizeSmall}`]: {
            fontSize: "12px",
            lineHeight: "12px",
            padding: children ? "6px 8px" : "6px",
            [`.${buttonClasses.startIcon}, .${buttonClasses.endIcon} `]: {
                "& > svg": {
                    height: "12px",
                    width: "12px",
                    minHeight: "12px",
                    minWidth: "12px",
                    margin: "0",
                },
            },
            [`.${buttonClasses.startIcon}`]: {
                marginLeft: 0,
                marginRight: children ? "6px" : 0,
            },
            [`.${buttonClasses.endIcon}`]: {
                marginLeft: children ? "6px" : 0,
                marginRight: 0,
            },
        },
        // size - medium
        [`&.${buttonClasses.sizeMedium}`]: {
            fontSize: "14px",
            lineHeight: "16px",
            padding: children ? "7px 11px" : "7px",
            ...(hasIcon && {
                padding: children ? "6px 11px" : "6px",
            }),
            [`.${buttonClasses.startIcon}, .${buttonClasses.endIcon} `]: {
                "& > svg, & > img": {
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    margin: "0",
                },
            },
            [`.${buttonClasses.startIcon}`]: {
                marginLeft: 0,
                marginRight: children ? "6px" : 0,
            },
            [`.${buttonClasses.endIcon}`]: {
                marginLeft: children ? "6px" : 0,
                marginRight: 0,
            },
        },
        // size - large
        [`&.${buttonClasses.sizeLarge}`]: {
            fontSize: "14px",
            lineHeight: "16px",
            padding: children ? "10px 15px" : "10px",
            ...(hasIcon && {
                padding: children ? "8px 15px" : "8px",
            }),
            [`.${buttonClasses.startIcon}, .${buttonClasses.endIcon} `]: {
                "& > svg": {
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    margin: "0",
                },
            },
            [`.${buttonClasses.startIcon}`]: {
                marginLeft: 0,
                marginRight: children ? "8px" : 0,
            },
            [`.${buttonClasses.endIcon}`]: {
                marginLeft: children ? "8px" : 0,
                marginRight: 0,
            },
        },
        // size - extralarge
        [`&.MuiButton-sizeExtralarge`]: {
            fontSize: "16px",
            lineHeight: "16px",
            padding: children ? "15px 22px" : "15px",
            ...(hasIcon && {
                padding: children ? "14px 22px" : "14px",
            }),
            [`.${buttonClasses.startIcon}, .${buttonClasses.endIcon} `]: {
                "& > svg": {
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    margin: "0",
                },
            },
            [`.${buttonClasses.startIcon}`]: {
                marginLeft: 0,
                marginRight: children ? "10px" : 0,
            },
            [`.${buttonClasses.endIcon}`]: {
                marginLeft: children ? "10px" : 0,
                marginRight: 0,
            },
        },
        svg: {
            margin: "2px",
            opacity: "1",
        },
        "&.Mui-disabled": {
            cursor: "default",
            opacity: 0.38,
            "& svg": {
                opacity: 0.38,
            },
        },
    }
})

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <ColorButton ref={ref} {...props} size={props.size ?? "medium"} variant={props.variant ?? "contained"}>
            {props.value || props.children}
        </ColorButton>
    )
})
