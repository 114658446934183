import { Article, ArticleAttribute, ErpInformation, Paging, ProductGroup } from ".."

export type TyresBundleParams = {
    articleListServiceUrlByQuery: string
    wheelsServiceURL: string
    tyresCritsServiceUrl: string
    tyresServiceUrl: string
    vehicleRecordsEnabled?: boolean
    defaultSeason?: TyresSeason
    pageSize: number
    maxAutoRequest: number
    minAvailableItems: number
    addOns: TyresCarTypeAddon[]
    availabilityFilterDefault?: boolean
    traderReferenceId?: number
    isFiltersOnRightSide?: boolean
    useNewNavbar?: boolean
}

export type TyresCarTypeAddon = {
    Id: number,
    Description: string
    ShortCode: string
    Default: boolean
}

// preserve key order because is displayed on summary and filters
export enum ETyresSeason {
    default = "default",
    summer = "summer",
    allSeason = "allSeason",
    winter = "winter",
}

export type TyresSeason = `${ETyresSeason}`;

export enum ETyresCarType {
    "NONE",
    "PKW",
    "SUV",
    "LLKW",
    "LKW",
    "Motorrad",
    "Anhanger",
    "Agrar",
    "EM",
    "Industrie",
    "Militar",
}

// EFilterIds on API as string
export enum EFilterNames {
    // none = "none",
    carType = "carType",
    season = "season",
    manufacturer = "manufacturer",
    availability = "availability",
    noiseLevel = "noiseLevel",
    weight = "weight",
    wetGripClass = "wetGripClass",
    fuelEfficiency = "fuelEfficiency",
    externalRolling = "externalRolling",
    runExtraLoad = "runExtraLoad",
    width = "width",
    height = "height",
    inch = "inch",
    loadIndex = "loadIndex",
    speedIndex = "speedIndex",
    oeIdentifier = "oeIdentifier",
    tyreSize = "tyreSize",
    carTypeAndSeason = "carTypeAndSeason",
    extras = "extras",
    tyreModel = "tyreModel",
    tyreManufacturer = "tyreManufacturer",

    // TODO extra fields where not data but implemented ?
    // silent = "silent",
    // studdable = "studdable",
    // studed = "studed",
};

export type FilterNames = `${EFilterNames}`;

export type TyreFilter = {
    value: string
    query: string
    sortPrio?: number
    info?: string
    group: FilterNames // EFilterIds as string
    groupId?: number // TMF filter id
    valueKey?: string // TODO find other way
    bitValue?: number // TODO find a way to get rid of this
}

export type GetArticleListByMatchCodeRequest = Paging & {
	query: string
	searchLevel: number
	selectedCriteria: TyreFilter[],
	fromFilter?: boolean
	traderReferenceId?: number
	carTypes: ETyresCarType[] | undefined
}

export type TyreArticle = Article & {
    erpInformation?: ErpInformation
    productGroup?: TyreProductGroup
	attributes: TyreArticleAttributes[]
}

export type TyreArticleAttribute = ArticleAttribute & {
	group: FilterNames
}

export type TyreArticleAttributes = {
	topAttributes: Array<TyreArticleAttribute>
	articleAttributes: Array<TyreArticleAttribute>
	vehicleAttributes: Array<TyreArticleAttribute>
	partsListAttributes: Array<TyreArticleAttribute>
}

export type TyreProductGroup = ProductGroup & {
    season: TyresSeason
    carType: ETyresCarType
}

export type GetArticleListByMatchCodeResponse = Paging & {
	uniArticles: TyreArticle[]
    searchLevel: number
    articleListCount: number
    usedCriteria: TyresUsedCriteria
    usedQuery: string
    defaultQuantity: number
}

export type TyresUsedCriteria = {
	width: TyreFilter
	height: TyreFilter
	inch: TyreFilter
	oeIdentifier: TyreFilter[]
	loadIndex: TyreFilter[]
	speedIndex: TyreFilter[]
	carTypeAndSeason: TyreFilter[]
	season: TyreFilter[]
}

export type GetArticlesListMappedResponse = {
	articleListCount: number
	uniArticles: TyreArticle[]
	usedCriteria: TyresUsedCriteria
	usedQuery: string
}

