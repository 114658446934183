import * as React from "react"
import { Size } from "../../models/skins";
import { getFieldErrors, equals, clone, getValue, setValue } from "@tm/utils"
import { createErrorElements,  FormElementProps, TextAutoComplete } from "../../models"
import Icon from "../icon"
import Tooltip from "../tooltip"
import { elementId, bindMethodsToContext } from "../../helper"
import Button from "../button"

export type InputGroupSizes = Size

export type TextFieldProps = FormElementProps & {
    autoComplete?: TextAutoComplete
    pattern?: RegExp
    multiline?: boolean
    type?: "text" | "number" | "password" | "email" | "tel" | "search" | "url"
    placeholder?: string
    maxLength?: number
    showLength?: boolean
    showClear?: boolean
    tooltip?: string
    id?: string
    preventConfirmOnBlur?: boolean
    preventBubbling?: boolean
    forceShowError?: boolean
    attachShowErrorTo?: HTMLElement | null
    ignoreLocalFormatting?: boolean
    size?: InputGroupSizes
    additionalInputIcons?: React.ReactNode
    forceShowErrorBorder?: boolean

    formatter?: (value: any) => string
    onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
    onChangeConfirm?(model: any, path?: Array<any>, vinValid?: boolean): void
    onChangeReset?(model: any, path?: Array<any>): void
    onKeyPress?(e: React.KeyboardEvent): void
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
    //  some inputMode should also have as props "type"
    inputMode?: "text" | "none" | "decimal" | "tel" | "search" | "url" | "numeric"
}

type State = {
    value: string
    inputValue: string
    edit: boolean
    showErrorMessage: boolean
}

export default class TextField extends React.Component<TextFieldProps, State> {
    private _inputRef: HTMLTextAreaElement | HTMLInputElement | null = null
    id = elementId()

    constructor(props: TextFieldProps) {
        super(props)

        const value = this.getValue(props)
        const { formatter, forceShowError } = props

        this.state = {
            value,
            inputValue: formatter ? formatter(value) : value,
            edit: false,
            showErrorMessage: !!forceShowError
        }

        bindMethodsToContext(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: TextFieldProps) {
        const value = this.getValue(this.props)
        const nextValue = this.getValue(nextProps)

        if (nextValue != value) {
            const { formatter } = nextProps
            this.setState({
                value: nextValue,
                inputValue: formatter ? formatter(nextValue) : nextValue,
            })
        }
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            this.focus()
        }
    }

    componentDidUpdate(prevProps: TextFieldProps) {
        if (this.props.autoFocus && !prevProps.autoFocus) {
            this.focus()
        }
    }

    getValue(props: TextFieldProps): string {
        const value = (props.model && props.path ? getValue(props.model, props.path) : props.value)
        return value != null ? (value.toLocaleString && !this.props.ignoreLocalFormatting ? value.toLocaleString() : value.toString()) : ""
    }

    select() {
        this._inputRef?.select()
    }

    setValueToModel(value: any): any {
        if (!this.props.path) {
            return
        }

        const oldValue = getValue(this.props.model, this.props.path, "")
        if (equals(value, oldValue)) {
            return this.props.model
        }

        const model = clone(this.props.model)
        setValue(model, this.props.path, !!value ? value : null)
        return model
    }

    handleRef(inputRef: HTMLTextAreaElement | HTMLInputElement | null) {
        this._inputRef = inputRef
        this.props.onRef?.(this)
    }

    handleChange(e: any) {
        let value: string | null = e.target.value
        if (value == "") {
            value = null
        }

        if (this.props.pattern && value) {
            const test = this.props.pattern.exec(value)
            if (test == null) { return }
            if (test[0] == "") { return }
            value = test[0]
        }

        const { formatter } = this.props
        let inputValue = value != null ? value : ""
        inputValue = formatter ? formatter(inputValue) : inputValue
        this.setState({
            value: inputValue,
            inputValue
        })

        if (this.props.model && this.props.path) {
            const model = this.setValueToModel(inputValue)
            if (this.props.onChange) {
                this.props.onChange(model, this.props.path)
            }
        }
        else if (this.props.onChange) {
            this.props.onChange(inputValue)
        }
    }

    handleFocus(e: React.FocusEvent) {
        if (this.props.readonly)
            return

        this.setState({
            edit: true,
            showErrorMessage: true
        })

        this.props.onFocus?.(e)
    }

    handleBlur(e: React.FocusEvent) {
        if (this.props.readonly)
            return

        this.setState({
            edit: false,
            showErrorMessage: !!this.props.forceShowError
        })

        if (!this.props.preventConfirmOnBlur)
            this.handleChangeConfirm()

        this.props.onBlur?.(e)
    }

    handleChangeConfirm() {
        if (this.props.onChangeConfirm) {
            const value = this.state.value
            if (this.props.model && this.props.path) {
                const model = this.setValueToModel(value)
                this.props.onChangeConfirm(model, this.props.path)
            }
            else {
                this.props.onChangeConfirm(value)
            }
        }
    }

    handleKeyUp(e: React.KeyboardEvent) {
        if (this.props.readonly)
            return

        const { formatter, model, path, value, onChangeReset } = this.props

        switch (e.which) {
            case 13: {
                if (!this.props.multiline) {
                    this.handleChangeConfirm()
                }
                break
            }
            case 27: {
                const originValue = this.getValue(this.props)
                this.setState({
                    value: originValue,
                    inputValue: formatter ? formatter(originValue) : originValue,
                })
                if (onChangeReset) {
                    if (model && path) {
                        onChangeReset(model, path)
                    }
                    else {
                        onChangeReset(value)
                    }
                }
                break
            }
        }
        this.props.onKeyPress?.(e)
    }

    handleClear(ev?: React.SyntheticEvent<HTMLButtonElement>) {
        ev?.preventDefault()
        const e = {
            target: {
                value: ""
            }
        }
        this.focus()
        this.handleChange(e)
    }

    handleMouseEnter = () => {
        this.setState({ showErrorMessage: true })
    }

    handleMouseOut = () => {
        this.setState(this.handleShowErrorMessageState)
    }

    handleShowErrorMessageState = (state: any) => {
        return {
            showErrorMessage: (this.props.forceShowError || state.edit)
        }
    }

    focus() {
        if (this.props.readonly)
            return

        if (!this.state.edit) {
            this.setState({
                edit: true,
                showErrorMessage: true
            })
        }

        if (this._inputRef) {
            setTimeout(() => {
                this._inputRef && this._inputRef.focus()
            }, 0)
        }
    }

    handleClick(ev: React.SyntheticEvent<HTMLInputElement>) {
        if (this.props.preventBubbling) {
            ev.stopPropagation()
            ev.preventDefault()
            ev.bubbles = false
        }
    }

    renderLengthCounter() {
        const maxLength = this.props.maxLength

        if (maxLength) {
            return (
                <label className="length-counter">
                    {this.state.inputValue.length} / {maxLength}
                </label>
            )
        }

        return (
            <label className="length-counter">
                {this.state.inputValue.length}
            </label>
        )
    }

    checkErrors() {
        if (this.props.modelState && this.props.path) {
            return getFieldErrors(this.props.modelState, this.props.path) || []
        }
        return []
    }

    renderErrors(errors: Array<string>) {
        if (errors.length) {
            return (
                <div className="error" style={{ minWidth: "fit-content", position: "absolute", left: -1, right: -1, pointerEvents: "none", zIndex: 2 }}>
                    {createErrorElements(errors)}
                </div>
            )
        }
    }

    render() {
        const { size, layout, label, className, readonly, showClear, disabled, onKeyDown, name, autoComplete, placeholder, forceShowError, inputMode } = this.props

        const id = this.props.id || this.id
        const errors = this.checkErrors()
        const hasErrors = !!errors.length
        const { showErrorMessage } = this.state
        const inputSize = size ? size : "m"

        let elClassName = "input input--textfield"
        elClassName += ` input--${inputSize}`
        elClassName += (hasErrors || this.props.forceShowErrorBorder) ? " field-error" : ""
        elClassName += hasErrors && (showErrorMessage || forceShowError) ? " field-error--show-message has-error" : ""
        elClassName += readonly ? " readonly" : ""
        elClassName += className ? ` ${className}` : ""
        elClassName += this.state.edit ? " is-active" : ""
        elClassName += showClear && !readonly ? " clearable" : ""
        elClassName += this.state.inputValue != "" ? " has-value" : ""
        elClassName += this.props.floatingLabel ? " input--floating-label" : ""

        layout && layout.forEach(element => {
            if (element == "dropshadow") {
                elClassName += ` has-${element}`
            }
            else {
                elClassName += ` input--${element}`
            }
        })

        const labelElement = label ? <label className="input__label" htmlFor={id}>{label}</label> : false
        const tabIndex = readonly ? 0 : this.props.tabIndex

        return (
            <div className={elClassName}>
                <div className="input__wrapper">
                    <div className="input__inner">
                        {labelElement}
                        {this.props.multiline ?
                            <textarea
                                autoComplete={autoComplete}
                                className="input__field input__field--multiline"
                                placeholder={placeholder}
                                value={this.state.inputValue}
                                ref={this.handleRef.bind(this)}
                                onChange={this.handleChange.bind(this)}
                                onKeyUp={this.handleKeyUp.bind(this)}
                                onFocus={this.handleFocus.bind(this)}
                                onBlur={this.handleBlur.bind(this)}
                                readOnly={!!readonly}
                                tabIndex={tabIndex}
                                disabled={!!disabled}
                                id={id}
                                onKeyDown={onKeyDown}
                                name={name}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseOut={this.handleMouseOut}
                            />
                            :
                            <input
                                autoComplete={autoComplete}
                                className="input__field"
                                type={this.props.type || "text"}
                                placeholder={placeholder}
                                value={this.state.inputValue}
                                ref={this.handleRef.bind(this)}
                                onChange={this.handleChange.bind(this)}
                                onKeyUp={this.handleKeyUp.bind(this)}
                                onFocus={this.handleFocus.bind(this)}
                                onBlur={this.handleBlur.bind(this)}
                                onClick={this.handleClick.bind(this)}
                                readOnly={!!readonly}
                                tabIndex={tabIndex}
                                disabled={!!disabled}
                                id={id}
                                maxLength={this.props.maxLength}
                                onKeyDown={onKeyDown}
                                name={name}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseOut={this.handleMouseOut}
                                inputMode={inputMode || "text"}
                            />
                        }
                        {this.props.tooltip ?
                            <div className="input__tooltip">
                                <Tooltip content={this.props.tooltip} position="bottom">
                                    <Icon name="info" size="s" />
                                </Tooltip>
                            </div>
                            : null
                        }

                        {
                            !readonly && showClear &&
                            <div className="input__icons">
                                <Button fakeButton layout={["ghost"]} icon={"close"} onClick={this.handleClear} style={{ visibility: this.state.value != "" ? "visible" : "hidden" }} />
                                {this.props.additionalInputIcons}
                            </div>
                        }
                        {this.props.showLength && this.state.inputValue ? this.renderLengthCounter() : null}
                    </div>
                    {showErrorMessage && this.renderErrors(errors)}
                </div>
            </div>
        )
    }
}
