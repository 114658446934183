import { AjaxCachingMode, SystemType, TitleType } from "./enumerations"

export type UserContext = {
    id?: string
    languageId?: number
    account?: Account
    principal?: Principal
    generalContact?: Contact
    generalAddress?: Address
    modules?: Array<UserModule>
    externalModules?: Array<ExternalModule>
    externalAuthenticationTokens: Record<string, string | undefined>
    parameter: UserContextParameters
    system: UserContextSystem
    hasTelesales: boolean
    hasMailRetoure:boolean
    activatableModules?: Array<UserModule>
    isAnonym: boolean
}

export type KeyValue = {
    key: string,
    value: string
}

export type AddOnDto = {
    id: number,
    description: string,
    keys?: Array<AddOnKeyDto>
}

export type AddOnKeyDto = {
    id: string,
    description: string,
    shortCode?: string,
    default?: boolean
}

export type UserModule = {
    description: string
    type: UserModuleType
    parameters?: Array<KeyValue>
    addOns?: Array<AddOnDto>
    [key: string]: any
    moduleSubId: number
    uiConfigKeys?: string[]
}

export type ExternalModule = {
    catalogDescription?: string
    description: string
    type: ExternalModuleType
    parameter: Array<{ key: string, value: string }>
    [key: string]: any
    replacedUrl?: string
    id: number
}

export type Account = {
    customerNo: string
    iKndNr?: number
    lfdNr?: number
    username: string
    password: string
    oneUserLogin?: boolean
}

export type Principal = {
    traderId: number
    customerId: number
    userId: number
    repairshopId?: string
    cooperationId?: string
    customerDisplayName?: string
}

export type Address = {
    addition: string
    city: string
    country: string
    state: string
    street: string
    zip: string
}

export type Contact = {
    title: TitleType
    name: string
    company: string
    addition: string
    phone: string
    fax: string
    email: string
    personnelNumber?: string
}

export enum UserModuleType {
    Undefined = 0,
    Analytics = 1,
    Catalog = 2,
    VehicleLookup = 3,
    CustomerInformation = 4,
    HaynesPro = 5,
    KfzWin = 6,
    PvPlus = 7,
    TecRmi = 8,
    UnipartsPremium = 9,
    FastCalculator = 10,
    TMTruck = 11,
    TruckGarage = 12,
    TechDataHaynesPro = 13,
    ServiceDataHaynesPro = 14,
    TechDataAllData = 15,
    AWDataAWDoc = 16,
    Autodata = 17,
    TechDataTecRmi = 19,
    ServiceDataTecRmi = 20,
    GTIDataDAT = 21,
    Schnellkalkulation = 23,
    MyTruck = 24,
    WheelsTyresConfigJfnMvc = 25,
    Telematics = 26,
    TMWheels = 27,
    TMTires = 28,
    SubUserAdministration = 29,
    Offers = 30,
    TMDamageCalculationEurotax = 31,
    GpiDataTemot = 32,
    OEResearch = 33,
    FastCheck = 34,
    FastCalculatorNext = 35,
    VehicleRecords = 36,
    Alert = 37,
    TMDamageCalculationDat = 38,
    DATRegistration = 39,
    Telesales = 40,
    Link = 41,
    WorkshopProfile = 42,
    TMEurotax = 43,
    MessagingModul = 44,
    OfferCarousel = 45,
    CatalogLight = 46,
    Bikes = 47,
    CommercialVehicles = 48,
    DMS = 49,
    NEXTShell = 50,
    OEReplacementChain = 51,
    TMDamageCalculationGlass = 52,
    TecRMITruck = 53,
    MyTruckPlus = 54,
    PartsIndicator = 55,
    DutchOrderPlates = 56,
    Carmunication = 57,
    TMDamageCalculationAsWegas = 58,
    SAPOCIIntegration = 59,
    FastService = 60,
    DemoActivation = 61,
    WatchList = 62,
    ArticleNotes = 63,
    FastDTC = 64,
    TMTrailer = 65,
    CentralOrder = 66,
    OeInformation = 67,
    ToolRent = 70,
    IQT = 71,
    TechDataHaynesProTruck = 72,
    Eds = 73,
    CostEstimation = 74,
    WorkTaskOrder = 75,
    MarginTool = 76,
    Sindri = 77,
    Feedback = 78,
    Partslife = 79,
    Login = 80,
    Drivemotive = 81,
    VeFiles = 82,
    ServiceBookletMacsDia = 83,
    AddToBasket = 84,
    RecommendedArticles = 85,
    AiSearch = 86
}

export enum ExternalModuleType {
    AutodataInspectionData = 1,
    AutodataTechnicalData = 2,
    DatGpi = 3,
    EurotaxGpi = 4,
    HaynesPro = 5,
    KfzWin = 6,
    PvPlus = 7,
    TecRmi = 8,
    VehicleLookup = 23,
    IDSearch = 23
}

export type UserContextParameters = {
    [key: string]: string | number | undefined | boolean
    addressGVS?: string
    ajaxCachingMode?: AjaxCachingMode
    cacheFrontendArticleListSec?: number
    catalogLight: boolean
    clientErpCachingTimespan?: number
    collectiveCustomer?: string
    customerReferenceNumber?: string
    /**Is used as fav-icon. */
    dealerIconUrl?:string
    fileImportBasket?: number
    hideDealerPartNumber: boolean
    hotline?: string
    orderDisabled: boolean
    orderingType: OrderingType
    positionChangeEArtNrHArtNr: boolean
    purchasePricesDisabled: boolean
    showCloseWorktaskButton?: boolean
    showDataSupplierLogos?: boolean
    showVoucherKVA: boolean
    showVoucherOrder: boolean
    showVoucherReturn: boolean
    traderAddress?: string
    urlLogoMoodPicture?: string
    urlAGB?: string
    hideDetailsButtonInDetailView?: boolean
    catalogBrandImage?: string
}

export enum OrderingType {
    NoCentralOrdering = 0,
    CentralOrderingWithWorkTaskOrder = 1,
    CentralOrderingWithoutWorkTaskOrder = 2,
}

export type UserContextSystem = {
    systemType: SystemType
    projectGuid: string
    traderDescription?: string
}

export enum EUserContextParameters{
    ModuleCostsDAT = "ModuleCosts",
    ModuleCostsGlassAndEurotax = "471"
}

export enum CisModuleParameterKeys{
    moduleCosts = "moduleCosts"
}

export enum ESubModules {
    ShowTd = 3,
    ShowEREClick = 10,
    ShowEREFlatrate = 11,
    ShowTd_Vivid = 14,
    ShowSk_DATFIOnline = 15,
    GrafischeTeilesucheDAT = 16,
    ShowAutodataPremiumPKW = 103,
    GrafischeTeilesucheEurotax = 151,
    TecRMI = 190,
    TecRMITruck = 200,
    ServiceCalculator = 204,
    DamageCalculationGlass = 254,
}

export enum LinkTarget {
    None = "0",
    Modal = "1",
    NewWindow = "2",
    IFrame = "3",
}

export enum QuantitySuggestionMode {
    Deactivated = 0,
    Activated = 1,
    ActivatedButDisabledByDefault = 2,
}
