import { Article, ErpInformationRequestItem } from "@tm/models"

export function createErpInformationRequestItem(
    article: Article,
    vehicleId?: string,
    selectedShipmentModeId?: string,
    foundBySearchTerm?: string,
    externalBasketItemId?: string
): ErpInformationRequestItem {
    return {
        itemId: article.id,
        wholesalerArticleNumber: article.traderArticleNo,
        dataSupplierArticleNumber: article.supplierArticleNo,
        dataSupplierId: article.supplier.id,
        dataSupplierName: article.supplier.name,
        productGroupId: article.productGroup.id,
        productGroupName: article.productGroup.name,
        articleDescription: article.description,
        quantityValue: article.quantity || 1,
        vehicleId,
        selectedShipmentModeId,
        foundBySearchTerm,
        externalBasketItemId,
    }
}
