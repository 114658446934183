import { useMemo } from "react"
import { Article, Note, NoteTypes, Vehicle } from "@tm/models"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export const mapArticleToNoteIdentifier = (article: Article | string): string => {
    return typeof article === "string" ? article : `${article.supplierArticleNo}_${article.supplier.id}`
}

export const mapArticleAndVehicleToNoteIdentifier = (article: Article | string, vehicle: Vehicle): string => {
    return typeof article === "string" ? article : `${article.supplierArticleNo}_${article.supplier.id}_${vehicle.tecDocTypeId}`
}

export const useNotes = () => {
    const article = useDefaultArticleItemState((x) => x.article)
    const vehicle = useDefaultArticleItemState((x) => x.vehicle)
    const { notes } = useDefaultArticleItemState((x) => x.notes)
    const { handleDelete, handleSave, isLoading, visible, hide } = useDefaultArticleItemState((x) => x.notes)

    const displayNotes = useMemo<Note[]>(() => {
        const list: Note[] = []

        list.push(
            notes.find((x) => x.type === NoteTypes.ARTICLE) ?? {
                noteId: mapArticleToNoteIdentifier(article),
                type: NoteTypes.ARTICLE,
                text: "",
            }
        )

        if (vehicle) {
            list.push(
                notes.find((x) => x.type === NoteTypes.VEHICLE_ARTICLE) ?? {
                    noteId: mapArticleAndVehicleToNoteIdentifier(article, vehicle),
                    type: NoteTypes.VEHICLE_ARTICLE,
                    text: "",
                }
            )
        }

        return list
    }, [article, notes, vehicle])

    return { displayNotes, handleDelete, handleSave, isLoading, visible, hide }
}
