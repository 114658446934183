import { atom } from "recoil"
import { Article, ShipmentMode } from "@tm/models"

export type OpenDetailsState = {
    article: Article | undefined
}

export const openDetailsState = atom<OpenDetailsState>({
    key: "parts.details.openDetailsState",
    default: { article: undefined },
})

export type DetailOrderOptionState = {
    orderOption: ShipmentMode | undefined
    refreshErp?: boolean
}

export const selectedOrderOptionState = atom<DetailOrderOptionState>({
    key: "parts.details.orderoption",
    default: { orderOption: undefined },
})

export type OpenExternalArticleDetailsState = {
    externalBasketItemId: string | undefined
}

export const selectedExternalArticleDetailsState = atom<OpenExternalArticleDetailsState>({
    key: "parts.details.externalArticleDetails",
    default: { externalBasketItemId: undefined },
})
