import { transitionsToBasket } from "./basket/transitions"
import { transitionsToParts } from "./parts/transitions"
import { transitionsToVehicle } from "./vehicle/transitions"
import { transitionsToWorkTask } from "./workTask/transitions"

const transitions = [
    ...transitionsToBasket,
    ...transitionsToParts,
    ...transitionsToVehicle,
    ...transitionsToWorkTask,
]

export default transitions
