import { Article, ArticleIdentifier, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useArticleByArticleNumberWithOptionalVehicle(request: ArticleIdentifier | undefined, language?: string) {
    const { articles } = useArticlesByArticleNumbersWithOptionalVehicle(request ? [request] : undefined, language)
    return articles?.first()
}

export function useArticlesByArticleNumbersWithOptionalVehicle(requests: Array<ArticleIdentifier> | undefined, language?: string) {
    const { data, isLoading, status } = useQuery({
        queryKey: ["parts_useArticlesByArticleNumbersWithOptionalVehicle", requests, language],
        queryFn: () => {
            return Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicle).subscribe(requests).load()
        },
        enabled: !!requests?.length,
        staleTime: 4 * 60 * 60 * 1000, // 4 hours
    })

    return { articles: data, isLoading, status }
}
