import { ComponentType, PropsWithChildren } from "react"
import { Box, Grid, styled, Typography } from "@mui/material"

type ModuleHeaderSlotComponentProps = PropsWithChildren<{ title?: string }>
type ModuleHeaderSlotProps = ModuleHeaderSlotComponentProps & {
    hideDivider?: boolean
    isTabsView?: boolean // use this when moduleHeader contains tabs to align items
}

function ModuleHeaderSlotComponent(props: ModuleHeaderSlotComponentProps) {
    const { title, children, ...otherProps } = props

    if (!title) {
        return (
            <Grid {...otherProps} display="grid">
                <Box>{children}</Box>
            </Grid>
        )
    }

    return (
        <Grid {...otherProps} display="grid" gridTemplateRows="12px 1fr">
            <Typography variant="label" fontSize={9} textTransform="uppercase" height={12}>
                {title}
            </Typography>
            <Box>{children}</Box>
        </Grid>
    )
}

export const ModuleHeaderSlot = styled(ModuleHeaderSlotComponent, {
    shouldForwardProp: (prop: string) => prop !== "isTabsView" && prop !== "hideDivider",
})<ModuleHeaderSlotProps>(({ isTabsView, hideDivider }) => ({
    position: "relative",
    paddingTop: isTabsView ? 0 : 8,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    height: "100%",
    ...(!hideDivider && {
        "&:not(:nth-child(2)):before": {
            position: "absolute",
            top: 0,
            left: 0,
            content: "''",
            borderLeft: "2px solid #c3c3c3",
            marginTop: "-.5rem",
            height: "75%",
        },
    }),
})) as ComponentType<ModuleHeaderSlotProps>
