import { TyreArticleItemState } from "@tm/models"
import { useArticleItemState } from "."

export function useTyresItemState(): TyreArticleItemState
export function useTyresItemState<TReturnType>(selector: (value: TyreArticleItemState) => TReturnType): TReturnType
export function useTyresItemState<TReturnType>(selector?: (value: TyreArticleItemState) => TReturnType): TyreArticleItemState | TReturnType {
    return useArticleItemState<TyreArticleItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
