import { memo } from "react"
import { Box, Grid, Stack, styled } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ArticleNumbers } from "../../ArticleNumbers"
import { DefaultArticleErpContainer } from "../../ErpContainer"
import { CenterActions } from "../../Actions/CenterActions"
import { Image } from "../../Image"
import { Supplier } from "../../Supplier"
import { Transactions } from "../../Transactions"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../../../generics/Icons"
import { Divider } from "../../../../generics/divider"
import { CompactAttributes } from "../../Attributes/CompactAttributes"
import { RightActions } from "../../Actions/RightActions"
import { ExpandIconButton, GridItem } from "./ArticleItemComponents"
import { CompactAdditionalInformations } from "../../AdditionalInformations/CompactAdditionalInformations"
import { Tooltip } from "../../../../generics/tooltip"
import { BonusSystem } from "../../BonusSystem"
import { useArticleItemState } from "../../ContextProviderStates"
import { SeasonIconWithTyreEfficiency } from "../Tyres/components/SeasonIconWithTyreEfficiency"

const RightContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !["hasNarrowRightActionsContainer", "showExtendedAvailabilityContainer"].includes(prop),
})<{ hasNarrowRightActionsContainer?: boolean; showExtendedAvailabilityContainer?: boolean }>(
    ({ hasNarrowRightActionsContainer, showExtendedAvailabilityContainer }) => ({
        alignSelf: "stretch",
        flex: !hasNarrowRightActionsContainer ? (showExtendedAvailabilityContainer ? "0 0 453px" : "0 0 423px") : "0 0 240px",
        display: "grid",
        gridTemplateColumns: "1fr auto auto",
        padding: "8px 12px",
    })
)

const RightInnerContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !["showExtendedAvailabilityContainer"].includes(prop),
})<{ showExtendedAvailabilityContainer?: boolean }>(({ showExtendedAvailabilityContainer }) => ({
    gap: 12,
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "flex-end",
    display: "flex",
    ".availabilityContainer": {
        width: showExtendedAvailabilityContainer ? 104 : undefined,
        ".availability-icon-with-tour": {
            minWidth: 0,
        },
    },
    ".transactions": {
        ".basketAmountField": {
            ".MuiInputBase-input": {
                paddingLeft: 4,
                width: 80,
            },
            "&[data-has-amountinfo] .MuiInputBase-input": {
                width: 56,
            },
        },
        ".costEstimationButton .MuiButton-startIcon>svg.icon": {
            width: "auto",
        },
    },
}))

const ImageContainer = styled(Box)({
    margin: "5px",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}) as typeof Grid

const SupplierSpacing = styled(Box)({
    width: "90px", // make sure to align the attribute number for all articles
})

export const DefaultCompactArticleContentComponent = memo(() => {
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)
    const showExtendedAvailabilityContainer = useDefaultArticleItemState((x) => x.showExtendedAvailabilityContainer)
    const { hasNarrowRightActionsContainer } = useDefaultArticleItemState((x) => x.options)
    const isTyres = useArticleItemState((x) => x.type === "Tyres")

    const { translateText } = useLocalization()

    const handleExpand = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, false)
        toggleExpand()
    }

    return (
        <Grid container direction="row" wrap="nowrap" alignItems="start" sx={{ minHeight: "5.5em" }}>
            <ImageContainer>
                {/* Article Image */}
                <Grid item>
                    <Stack spacing={1} alignItems="flex-start" maxWidth="36px">
                        <Image />
                        <BonusSystem />
                    </Stack>
                </Grid>

                {/* Expand Icon */}
                <Grid item>
                    <Tooltip title={translateText(12569)}>
                        <ExpandIconButton onClick={handleExpand} hoverEffect disableRipple>
                            <Icon name="plus" />
                        </ExpandIconButton>
                    </Tooltip>
                </Grid>
            </ImageContainer>

            {/* Article Infos */}
            <GridItem item xs p={1} overflow="hidden">
                <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack>
                            <Stack
                                justifyContent="start"
                                spacing={1}
                                alignItems="center"
                                divider={<Divider orientation="vertical" flexItem />}
                                display="flex"
                                direction="row"
                            >
                                <SupplierSpacing>
                                    <Supplier compactView />
                                </SupplierSpacing>
                                <ArticleNumbers />
                            </Stack>
                        </Stack>
                        <CenterActions />
                    </Stack>

                    <Stack spacing="4px" direction="row" key="vehicleAttribute" alignItems="center">
                        {!isTyres && (
                            <>
                                <CompactAttributes />
                                <CompactAdditionalInformations />
                            </>
                        )}
                        {isTyres && <SeasonIconWithTyreEfficiency isCompact />}
                    </Stack>
                </Stack>
            </GridItem>

            {/* Actions, ERP Infos */}
            <RightContainer
                hasNarrowRightActionsContainer={hasNarrowRightActionsContainer}
                showExtendedAvailabilityContainer={showExtendedAvailabilityContainer}
            >
                <Box position="relative" alignContent="end">
                    <RightActions />
                    <RightInnerContainer showExtendedAvailabilityContainer={showExtendedAvailabilityContainer}>
                        <DefaultArticleErpContainer />
                        <Transactions />
                    </RightInnerContainer>
                </Box>
            </RightContainer>
        </Grid>
    )
})
