import { ApiBundleParams } from "./api"
import { BasketBundleParams } from "./basket"
import { CarmunicationBundleParams } from "./carmunication"
import { CisBundleParams } from "./cis"
import { CompilationsBundleParams } from "./compilations"
import { CrmBundleParams } from "./crm"
import { DamageCalculationAswegasBundleParams } from "./damageCalculationAswegas"
import { DamageCalculationDatBundleParams } from "./damageCalculationDat"
import { DamageCalculationEurotaxBundleParams } from "./damageCalculationEurotax"
import { DamageCalculationGlassBundleParams } from "./damageCalculationGlass"
import { DmsBundleParams } from "./dms"
import { DutchOrderPlatesBundleParams } from "./dutchOrderPlates"
import { DrivemotiveBundleParams } from "./drivemotive"
import { EdsBundleParams } from "./eds"
import { ErpBundleParams } from "./erp"
import { FastCalculatorBundleParams } from "./fastCalculator"
import { FastDtcBundleParams } from "./fastDtc"
import { FastServiceBundleParams } from "./fastService"
import { MiscBundleParams } from "./misc"
import { MvcBundleParams } from "./mvc"
import { NotificationsBundleParams } from "./notifications"
import { OffersBundleParams } from "./offers"
import { PartsBundleParams } from "./parts"
import { RepairTimesBundleParams } from "./repairtimes"
import { SettingsBundleParams } from "./settings"
import { SindriBundleParams } from "./sindri"
import { PartslifeBundleParams } from "./partslife"
import { TelematicsBundleParams } from "./telematics"
import { TelesalesBundleParams } from "./telesales"
import { TyresBundleParams } from "./tyres"
import { VehicleBundleParams } from "./vehicle"
import { VouchersBundleParams } from "./vouchers"
import { WheelsBundleParams } from "./wheels"
import { WorkTaskBundleParams } from "./worktask"
import { VehicleRecordsBundleParams } from "./vehicleRecords"
import { FeedbackBundleParams } from "./feedback"
import { MemoToolBundleParams } from "./memoTool"
import { RepairTimeProviderConfig } from "../works"
import { VefilesBundleParams } from "./vefiles"

export type {
    ApiBundleParams,
    BasketBundleParams,
    CarmunicationBundleParams,
    CisBundleParams,
    CompilationsBundleParams,
    CrmBundleParams,
    DamageCalculationAswegasBundleParams,
    DamageCalculationDatBundleParams,
    DamageCalculationEurotaxBundleParams,
    DamageCalculationGlassBundleParams,
    DmsBundleParams,
    DutchOrderPlatesBundleParams,
    DrivemotiveBundleParams,
    EdsBundleParams,
    ErpBundleParams,
    FastCalculatorBundleParams,
    FastDtcBundleParams,
    FastServiceBundleParams,
    FeedbackBundleParams,
    MemoToolBundleParams,
    MiscBundleParams,
    MvcBundleParams,
    NotificationsBundleParams,
    OffersBundleParams,
    PartsBundleParams,
    RepairTimesBundleParams,
    SettingsBundleParams,
    SindriBundleParams,
    PartslifeBundleParams,
    TelematicsBundleParams,
    TelesalesBundleParams,
    TyresBundleParams,
    VehicleBundleParams,
    VehicleRecordsBundleParams,
    VouchersBundleParams,
    WheelsBundleParams,
    WorkTaskBundleParams,
    VefilesBundleParams,
}

export type { PartsRoutes, ArticleListSortingMode } from "./parts"
export type { SortingFilterType, ModuleAvailabilities, TruckModuleAvailabilities, TruckModuleType } from "./vehicle"
export * from "./tyres"
export type { PdfOrderDetailOptions } from "./cis"

export type EmbeddedElement = {
    env?: string | string[]
    src?: string
    attribute?: { name: string, value: string }
    innerHTML?: string
    element?: "script" | "noscript"
    async?: boolean
    defer?: boolean
}

export type EmbeddedPosition = {
    head?: EmbeddedElement[],
    body?: EmbeddedElement[]
}

export type ConfigParams = {
    env: "dev" | "test" | "int" | "prod"

    articleDetailsInModal?: boolean
    displaySelectedOffersCatagory?: boolean
    articleFiltersInLeft?: boolean
    /**
     * The base url pointing to the controller (without endpoint)
     * of the DataPicker service used for autosuggest.
     */
    autosugestServiceUrl: string
    catalogName: string
    /**
     * If this parameter is "true" the alternative article search button will be hidden and instead
     * the search result will be combined with the alternative articles received from the ERP response
     * and both displayed in the "alternative articles" tab at the article details page.
     */
    combineAlternativeArticlesFromDbAndErp?: boolean
    deleteButtonColorError?: boolean
    enableAddingButtonsAfterErp?: boolean
    externalScripts?: Partial<Record<string, EmbeddedPosition>>
    hideDigitalVKHTile?: boolean
    nationalFlagsBaseUrl: string
    nextToursTimeoutRandomSecondsMultiplier?: number
    replacePartDirectSearchUrl: string
    replacePartUniversalSearchUrl?: string
    repairTimeProviders?: { [key: string]: RepairTimeProviderConfig }
    sapCXMLIntegrationAvailable?: boolean
    sapOCIIntegrationAvailable?: boolean
    showPartsyProductGroupsInModal?: boolean
    tokenHandler?: Record<string, string>
    userSettingsDefaults: UserSettingsDefaults
    oAuthConfiguration?: Partial<Record<OAuthTokenIds, OAuthConfig>>
}

export type UserSettingsDefaults = {
    hideWheelsAvailability?: boolean
}

export type BaseConfig = {
    authorityService: string
    stateServiceUrl: string
    keyValueServiceUrl: string
    repairShopServiceUrl: string
    systems?: string[]
    mvcBaseUrl: string
    actionsBaseUrl: string
    tmChatBaseUrl: string
    partsSearchTreeIconsBaseUrl: string
    fastUpApiBaseUrl: string
    erpServiceUrl: string
}

export type AppConfig = {
    name: string
    isBeta?: boolean
    base: BaseConfig
    theme: any,
    templates: {
        start: number | undefined
        dashboard: {
            type: "TM" | "ONE" | "THREE" | "FOUR"
            showTyresWidget?: boolean
            showTruckWidget?: boolean
            showDamageCalculationWidget?: boolean
        }
    }
    bundles: {
        api?: ApiBundleParams
        basket?: BasketBundleParams
        carmunication?: CarmunicationBundleParams
        cis?: CisBundleParams
        compilations?: CompilationsBundleParams
        crm?: CrmBundleParams
        damageCalculationAswegas?: DamageCalculationAswegasBundleParams
        damageCalculationDat?: DamageCalculationDatBundleParams
        damageCalculationEurotax?: DamageCalculationEurotaxBundleParams
        damageCalculationGlass?: DamageCalculationGlassBundleParams
        dms?: DmsBundleParams
        dutchOrderPlates?: DutchOrderPlatesBundleParams
        erp?: ErpBundleParams
        fastCalculator?: FastCalculatorBundleParams
        fastDtc?: FastDtcBundleParams
        fastService?: FastServiceBundleParams
        feedback?: FeedbackBundleParams
        memoTool?: MemoToolBundleParams
        misc?: MiscBundleParams
        mvc?: MvcBundleParams
        notifications?: NotificationsBundleParams
        offers?: OffersBundleParams
        parts?: PartsBundleParams
        repairtimes?: RepairTimesBundleParams
        settings?: SettingsBundleParams
        telematics?: TelematicsBundleParams
        telesales?: TelesalesBundleParams
        tyres?: TyresBundleParams
        vehicle?: VehicleBundleParams
        vehicleRecords?: VehicleRecordsBundleParams
        vouchers?: VouchersBundleParams
        wheels?: WheelsBundleParams
        worktask?: WorkTaskBundleParams
    }
    params: ConfigParams
    modules: {
        articleNotes?: boolean
        autodata?: boolean
        awdataAwdoc?: boolean
        bikes?: boolean
        carmunication?: boolean
        centralOrders?: boolean
        commercialVehicles?: boolean
        customerInformation?: boolean
        demoActivation?: boolean
        dms?: boolean
        dutchOrderPlates?: boolean
        fastCalculator?: boolean
        fastCheck?: boolean
        fastDtc?: boolean
        fastSearch?: boolean
        fastService?: boolean
        gpiDataTemot?: boolean
        gpiDataDat?: boolean
        gpiDataEurotax?: boolean
        messaging?: boolean
        myTruck?: boolean
        myTruckPlus?: boolean
        oeReplacementChain?: boolean
        oeResearch?: boolean
        offerCarousel?: boolean
        offers?: boolean
        partsIndicator?: boolean
        sapOciOntegration?: boolean
        techDataAlldata?: boolean
        techDataHaynesPro?: boolean
        techDataTecRmi?: boolean
        telematics?: boolean
        tmDamageCalculationAswegas?: boolean
        tmDamageCalculationDat?: boolean
        tmDamageCalculationEurotax?: boolean
        tmDamageCalculationGlass?: boolean
        tmEurotax?: boolean
        tmTyres?: boolean
        tmTrailer?: boolean
        tmTruck?: boolean
        tmWheels?: boolean
        truckGarage?: boolean
        vehicleRecords?: boolean
        watchlist?: boolean
        wheelsTyresConfigJfnMvc?: boolean
        workshopProfile?: boolean
        recommendedArticles?: boolean
    }
    basket?: {
        orderButton?: {
            externalSystemId?: number
        }
    }
}

// Extend this when new tokens are added
export enum OAuthTokenIds {
    BoschOs3 = "bosch-os3",
}

export type OAuthConfig = {
    server: string | {
        production: string
        staging: string
        overwrite?: string
    },
    clientId: string
    discoveryEndpoint?: string
    scope?: string[]
    extraParams?: Record<string, string>
}
