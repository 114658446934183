import { Memo } from "@tm/models"
import { Stack, styled, Typography } from "@mui/material"

type Props = {
    memos: Memo[]
}

const StyledTable = styled("table")(({ theme }) => ({
    flex: 1,
    "&:first-child": {
        marginRight: "16px",
    },
    tr: {
        td: {
            padding: "8px",
        },

        "&:nth-child(odd)": {
            backgroundColor: theme.palette.grey[100],
        },
    },
}))

const StyledWrapper = styled(Stack)({
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
})

export function MemoTooltipContent({ memos }: Props) {
    const left = memos.filter((_, index) => index % 2 === 0)
    const right = memos.filter((_, index) => index % 2 !== 0)

    function getRow(memo: Memo) {
        let text = <Typography>{memo.text}</Typography>

        if (memo.linkUrl) {
            text = (
                <a href={memo.linkUrl} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            )
        }

        return (
            <tr>
                <td>
                    <Typography>{memo.label}</Typography>
                </td>
                <td>{text}</td>
            </tr>
        )
    }

    return (
        <StyledWrapper>
            <StyledTable>
                <tbody>{left.map((memo) => getRow(memo))}</tbody>
            </StyledTable>
            <StyledTable>
                <tbody>{right.map((memo) => getRow(memo))}</tbody>
            </StyledTable>
        </StyledWrapper>
    )
}
