import { Article, RegisteredModels } from "@tm/models"
import { Dictionary } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

const KEY = "parts_useArticlesByWholesalerArticleNumbers"

export function useArticlesByWholesalerArticleNumbers(wholesalerArticleNumbers: string[] | undefined) {
    const { data, isLoading, status } = useQuery(
        [KEY, wholesalerArticleNumbers],
        () => {
            return Container.getInstance<Dictionary<Article[]>>(RegisteredModels.Articles_ByWholesalerArticleNumbers)
                .subscribe(wholesalerArticleNumbers)
                .load()
        },
        {
            staleTime: 4 * 60 * 60 * 1000, // 4 hours
            enabled: !!wholesalerArticleNumbers?.length,
        }
    )

    return { articles: data, isLoading, status }
}
