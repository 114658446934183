import { useLocalization } from "@tm/localization"
import { Memo, MemoType } from "@tm/models"
import { MemoTooltipContent } from "./MemoTooltipContent"
import { Icon } from "../../../../generics/Icons"
import { Tooltip } from "../../../../generics/tooltip"
import { Typography } from "../../../../generics/typographie"

type Props = {
    memos: Memo[]
    iconName: string
}

export function MemoTooltip({ memos, iconName }: Props) {
    const { translateText } = useLocalization()

    if (!iconName) {
        return null
    }

    const memosFiltered = memos?.filter((m) => m.type === MemoType.Hyperlink || m.type === MemoType.AdditionalDescription)
    if (!memosFiltered?.length) {
        return null
    }

    return (
        <Tooltip
            variant="light"
            noWidthLimit
            contentPadding="16px"
            title={
                <>
                    <Typography>{translateText(180)}</Typography>
                    <MemoTooltipContent memos={memos} />
                </>
            }
        >
            <Icon color="highlight" name={iconName} />
        </Tooltip>
    )
}
