import { forwardRef, useCallback, useRef } from "react"
import {
    alpha,
    filledInputClasses,
    FilledTextFieldProps,
    formHelperTextClasses,
    inputLabelClasses,
    StandardTextFieldProps,
    styled,
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
    colors,
    outlinedInputClasses,
    FilledInputProps,
    Stack,
} from "@mui/material"
import { CSSProperties } from "@mui/styles"
import { IconButton } from "../button"
import { Icon } from "../Icons"
import { Typography } from "../typographie"

export type TextFieldProps = (MuiTextFieldProps | StandardTextFieldProps | FilledTextFieldProps) & {
    variant?: "filled" | "standard" | "outlined" | undefined
    inputCounter?: boolean
    clearButton?: boolean
    hideNumericCounter?: boolean
}

const StyledTextField = styled(MuiTextField, {
    shouldForwardProp: (prop) => prop !== "inputCounter" && prop !== "clearButton" && prop !== "endAdornment" && prop !== "disableUnderline",
})<TextFieldProps>(({ theme, size, hideNumericCounter, hiddenLabel }) => {
    const styles: CSSProperties = {
        boxSizing: "border-box",
        marginTop: "14px",

        ...(size === "large" && {
            marginTop: "0",
        }),

        // Size Extralarge
        ...(size === "extralarge" && {
            marginTop: "0",
        }),

        // Hide Counter on type="number"
        ...(hideNumericCounter && {
            "& input[type=number]": {
                "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
        }),

        // Display error Helpertext
        "&:hover": {
            [`.${formHelperTextClasses.root}`]: {
                [`&.${formHelperTextClasses.error}`]: {
                    display: "block",
                },
            },
        },

        // Counter
        "& .inputCounter": {
            position: "absolute",
            top: "-17px",
            fontSize: "10px",
            right: "6px",

            // Size small
            ...(size === "small" && {
                top: "-19px",
            }),

            // Size Large
            ...(size === "large" && {
                top: "-5px",
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                top: "-2px",
            }),
        },

        // Close Icon, EndAdornment
        "& .inputButtons": {
            right: "5px",
            padding: "4px",

            // Size Large
            ...(size === "large" && {
                top: "8px",
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                top: "14px",
            }),
        },

        // Label
        [`.${inputLabelClasses.filled}`]: {
            fontSize: "10px",
            transform: "translate(4px,-14px) scale(1)",
            textTransform: "uppercase",
            opacity: 0.54,

            "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(4px,-14px) scale(1)",
            },

            // Size small
            ...(size === "small" && {
                fontSize: "9px",
                transform: "translate(2px,-12px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(2px,-12px) scale(1)",
                },
            }),

            // Size Large
            ...(size === "large" && {
                fontSize: "10px",
                transform: "translate(9px,2px) scale(0.87)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(9px,2px) scale(0.87)",
                },
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                fontSize: "10px",
                transform: "translate(12px,3px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(12px,3px) scale(1)",
                },
            }),
        },

        // Input
        [`.${filledInputClasses.input}`]: {
            background: "none",

            // Size Medium
            padding: "8px 9px",
            fontSize: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
            lineHeight: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
            height: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",

            // Size Small
            ...(size === "small" && {
                padding: "6px",
                fontSize: theme.overwrites?.components?.textfield?.fontSize?.small || "12px",
                lineHeight: theme.overwrites?.components?.textfield?.fontSize?.small || "12px",
                height: theme.overwrites?.components?.textfield?.fontSize?.small || "12px",
            }),

            // Size Large
            ...(size === "large" && {
                padding: hiddenLabel ? "11px 9px" : "16px 9px 6px",
                fontSize: theme.overwrites?.components?.textfield?.fontSize?.large || "14px",
                lineHeight: theme.overwrites?.components?.textfield?.fontSize?.large || "14px",
                height: theme.overwrites?.components?.textfield?.fontSize?.large || "14px",
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                padding: hiddenLabel ? "15px 11px" : "21px 11px 9px",
                fontSize: theme.overwrites?.components?.textfield?.fontSize?.extralarge || "16px",
                lineHeight: theme.overwrites?.components?.textfield?.fontSize?.extralarge || "16px",
                height: theme.overwrites?.components?.textfield?.fontSize?.extralarge || "16px",
            }),

            "&.MuiSelect-select": {
                height: "17px",
            },

            "&.MuiInputBase-inputMultiline": {
                padding: 0,
            },
        },

        // Variant Outline
        [`.${outlinedInputClasses.input}`]: {
            padding: "6px 11px",
        },

        // Filled Root
        [`.${filledInputClasses.root}`]: {
            border: `1px solid ${theme.overwrites?.components?.textfield?.border?.color || "#e2e2e1"}`,
            borderRadius: theme.radius?.default || "3px",
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),

            "&:hover": {
                backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            },
            [`&.${filledInputClasses.focused}`]: {
                backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
                borderColor: theme.palette.primary.main,
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            },
            [`&.${filledInputClasses.error}`]: {
                borderColor: theme.palette.error.main,
                borderBottom: `4px solid ${theme.palette.error.main}`,
                marginBottom: "0px",
                [`&.${filledInputClasses.focused}`]: {
                    boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px`,
                },
            },
        },

        ".MuiInputBase-multiline": {
            paddingTop: "14px",
            [`&.MuiInputBase-sizeSmall`]: {
                paddingTop: "6px",
            },
            ...(size === "large" && {
                paddingTop: "28px",
            }),
        },

        // Helper Text / Error Text
        [`.${formHelperTextClasses.root}`]: {
            [`&.${formHelperTextClasses.contained}`]: {
                display: "none",
                [`&.${formHelperTextClasses.error}`]: {
                    [`&.${formHelperTextClasses.focused}`]: {
                        display: "block",
                    },
                    position: "absolute",
                    bottom: 0,
                    marginLeft: "0",
                    marginTop: "0",
                    borderRadius: "0 0 4px 4px",
                    color: theme.palette.error.contrastText,
                    backgroundColor: theme.palette.error.main,
                    width: "100%",
                    fontSize: "11px",
                    paddingLeft: "5px",
                    transform: "translateY(15px)",
                },
            },
        },
    }
    return styles
})

/**
 * The `TextField` is a NOT the real Material TextBox.
 * It overrides a lot of styles to looks like our well known TextField
 *
 * return <TextField id="time" type="time" inputProps={inputProps} />;
 * ```
 *
 * API:
 * - [TextField API](https://mui.com/api/text-field/)
 * - inherits [FormControl API](https://mui.com/api/form-control/)
 */
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
    const inputRefLocal = useRef<HTMLInputElement>(null)
    const inputRef = (typeof props.inputRef !== "function" ? props.inputRef : undefined) ?? inputRefLocal

    const handleClear = useCallback(() => {
        if (inputRef.current) {
            // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js/46012210#46012210
            // React doesn't recognize the setting of  ref.value = ""
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set
            nativeInputValueSetter?.call(inputRef.current, "")
            inputRef.current.dispatchEvent(new Event("input", { bubbles: true, cancelable: true }))
            inputRef.current.focus({})
        }
    }, [])

    const requireEndAdornment = props.InputProps?.endAdornment || props.inputCounter || props.clearButton
    const inputProps: Partial<FilledInputProps> = {
        disableUnderline: true,
        ...props.InputProps,
        endAdornment: requireEndAdornment ? (
            <>
                {props.inputCounter && (
                    <Typography className="inputCounter" variant="label">
                        {(props.value as string)?.length ?? 0}
                        {!!props.inputProps?.maxLength && `/${props.inputProps.maxLength}`}
                    </Typography>
                )}
                <Stack direction="row" gap={0.5} alignItems="center" className="inputButtons">
                    {props.InputProps?.endAdornment}
                    {props.clearButton && !!props.value && (
                        <IconButton
                            size="small"
                            sx={{ padding: props.inputCounter ? "0 2px 2px 0" : undefined }}
                            disableTouchRipple
                            onClick={handleClear}
                        >
                            <Icon name="close" />
                        </IconButton>
                    )}
                </Stack>
            </>
        ) : undefined,
    }

    return (
        <StyledTextField
            ref={ref}
            inputRef={inputRef}
            variant={props.variant || "filled"}
            autoComplete="off"
            size={props.size || "medium"}
            {...props}
            InputProps={inputProps}
            value={props.value ?? ""}
        />
    )
})
